<template>
  <v-expand-transition>
    <v-col cols="12" v-if="pointOptions && pointOptions.length">
      <v-select
        :items="pointOptions === 'loading' ? [] : pointOptions"
        item-value="id"
        item-text="name"
        v-model="tournament.pointSystems"
        label="Point System"
        :loading="pointOptions === 'loading'"
        attach chips deletable-chips multiple
        :disabled="disabled"
        color="color3"
        item-color="color3"
        return-object
      ></v-select>
    </v-col>
  </v-expand-transition>
</template>

<script>
export default {
  props: ['disabled', 'tournament', 'username'],
  data () {
    return {
      pointOptions: null
    }
  },
  methods: {
    checkForPoints () {
      this.pointOptions = 'loading'
      this.$VBL.organization.pointSystems.getOptions(this.username)
        .then(r => {
          this.pointOptions = r.data
          if (this.tournament.id === 0) {
            this.addDefaults()
          }
        })
        .catch(e => {
          console.log(e.response)
          this.pointOptions = null
        })
    },
    addDefaults () {
      const defaults = this.pointOptions.filter(f => f.isDefault)
      const add = defaults.filter(f => !this.tournament.pointSystems.includes(f))
      this.tournament.pointSystems.push(...add)
    }
  },
  mounted () {
    this.checkForPoints()
  }
}
</script>
