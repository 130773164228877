<template>
  <v-card light>
    <v-toolbar color="color1 color1Text--text">
      <v-toolbar-title>Team Importer</v-toolbar-title>
    </v-toolbar>
    <loading-bar v-model="loading"></loading-bar>
    <v-expand-transition>
      <div v-if="currentStep">
        <!-- TITLE ROW -->
        <v-card-title class="title font-weight-regular justify-space-between" ref="topOfPage">
          <span>{{ currentStep.title }}</span>
        </v-card-title>
        <!-- WINDOW STEPPER -->
        <v-card-text class="pt-0">
          <v-window v-model="window" touchless>
            <v-window-item v-for="step in steps" :key="step.value" :value="step.value">
              <!-- TOURNAMENT SELECTION -->
              <v-card color="grey lighten-4" v-if="step.type === 'tournament-select'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" class="title">
                      Have you already created the tournament on this site?
                      <div class="subheading mt-1"></div>
                    </v-col>
                    <v-col cols="12">
                      <v-btn color="success" @click.stop="selector = true" class="mr-3">yes</v-btn>
                      <v-btn color="error" @click.stop="createTemp">No</v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-if="selector">
                      <v-row dense>
                        <v-col cols="12" class="subheading">
                          Please select the tournament
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            :items="tournamentList"
                            item-text="name"
                            item-value="id"
                            v-model="tournamentId"
                            label="Select a tournament"
                            persistent-hint
                            :loading="loading"
                            color="color3"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-container>
              </v-card>
              <!-- TOURNAMENT COMPLETE -->
              <v-card color="grey lighten-4" v-if="step.type === 'tournament-complete'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12">
                      <v-alert type="warning" :value="true" class="black--text" >
                        The tournament you selected is already complete
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        color="color3"
                        label="I understand this will overwrite the current results"
                        v-model="overComplete"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- TOURNAMENT CREATE WARNING -->
              <v-card color="grey lighten-4" v-if="step.type === 'tournament-create'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" class="title">
                      Tournament quick add is for past events only
                      <div class="subheading mt-1">The quick add is very limited and does not provide full functionality.</div>
                      <div class="subheading mt-1">To add a future event or a more detailed event, go to the home tab.</div>
                    </v-col>
                    <v-col cols="12">
                      <v-btn color="color3" text large @click.stop="onNextClick">Got it</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- TOURNAMENT CREATE -->
              <v-card color="grey lighten-4" v-if="step.type === 'tournament-create2'">
                <v-container v-if="tempT">
                  <v-row dense>
                    <v-col cols="12" sm="9">
                      <v-text-field
                        label="Tournament Name*"
                        v-model="tempT.name"
                        color="color3"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <sanctioning-bodies :tournament="tempT"></sanctioning-bodies>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <date-time-picker
                        :datetime.sync="tempT.startDate"
                        label="Start"
                        type="date"
                        :past="!user.vbl"
                      ></date-time-picker>
                    </v-col>
                    <point-system-options
                      :username="username"
                      :tournament="tempT"
                      v-if="tempT"
                    ></point-system-options>
                  </v-row>
                </v-container>
              </v-card>
              <!-- FILE PICKER -->
              <v-card color="grey lighten-4" v-if="step.type === 'select-file'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" class="title">
                      {{ currentFile ? 'You are currently using:' : 'Which file would you like to upload?'}}
                    </v-col>
                    <v-expand-transition>
                      <v-col cols="12" v-if="currentFile" class="subheading">
                        {{currentFile.name}}
                      </v-col>
                    </v-expand-transition>
                    <v-col cols="12">
                      <v-btn
                        raised
                        large
                        class="color3 white--text"
                        @click="onPickFile"
                        :disabled="busy"
                      >{{`Choose a ${currentFile ? 'new ' : ''}File`}}</v-btn>
                      <input
                        type="file"
                        style="display: none"
                        ref="fileInput"
                        accept=".csv"
                        @change="onFilePicked">
                  </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- VERIFY DATA -->
              <v-card color="grey lighten-4" v-if="step.type === 'verify-data'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" class="title">
                      Make sure this is the right data
                      <div class="subheading mt-1">Double check if the column headers are correct and uncheck the box below if your file did not have headers</div>
                    </v-col>
                    <v-col cols="12" class="title">
                      <v-checkbox
                        color="color3"
                        label="My file has column headers"
                        v-model="hasHeader"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :items="rows"
                        :headers="headers"
                        v-if="parseResuls"
                        class="elevation-1"
                        dense
                      >
                      </v-data-table>
                  </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- DIVISION MAPPING -->
              <v-card color="grey lighten-4" v-if="step.type === 'division-mapping'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" class="title">
                      Which column of your file is the division?
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="title">
                      <v-select
                        :items="columnOptions"
                        v-model="divisionColumn"
                        label="Column"
                        hint="Choose a column"
                        dense
                        required
                        :rules="[(v) => !!v || 'Choose a column']"
                        color="color3"
                        item-color="color3"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-if="divisionColumn">
                      <v-row dense>
                        <v-col cols="12" class="title">
                          Great, now let's map the divisions in your .CSV file to the divisions of the tournament
                        </v-col>
                        <v-col cols="12">
                          The divisions in your CSV file are on the left. We will import all teams with that division into the division you select on the right.
                        </v-col>
                      </v-row>
                        <v-form v-model="validDivMap" ref="divForm">
                          <v-data-table
                            hide-default-footer
                            :items="divisionList.map(m => {return { id: m } })"
                            :headers="[
                              {text: 'Your Division', sortable: false, align: 'center', value: 'a'},
                              {text: '', sortable: false, align: 'center', value: 'b'},
                              {text: 'Tournament Division', sortable: false, align: 'left', value: 'c'}
                            ]"
                            class="elevation-1"
                            style="width: 500px; min-width: 500px; max-width: 500px"
                            :options="{itemsPerPage: -1}"
                          >
                            <template v-slot:[`item.a`]="{ item }">
                              {{item.id}}
                            </template>
                            <template v-slot:[`item.b`]="{ }">
                              <v-icon>far fa-exchange-alt</v-icon>
                            </template>
                            <template v-slot:[`item.c`]="{ item }">
                                <v-select
                                  :items="divisionOptions"
                                  v-model="divisionMap[item.id]"
                                  item-text="name"
                                  return-object
                                  label="Division*"
                                  hint="Select a division"
                                  dense
                                  single-line
                                  color="color3"
                                  item-color="color3"
                                  :rules="[(v) => !!v || 'Choose a column']"
                                  style="white-space: nowrap; text-overflow: ellipsis;"
                                ></v-select>
                            </template>
                          </v-data-table>
                        </v-form>
                    </div>
                  </v-expand-transition>
                    <v-expand-transition>
                      <div v-if="showAdjust">
                        <v-row dense>
                          <v-col cols="12">
                            <v-checkbox label="Auto Adjust Gold/Silver Brackets" v-model="autoAdj"></v-checkbox>
                          </v-col>
                        </v-row>
                      </div>
                    </v-expand-transition>
                </v-container>
              </v-card>
              <!-- COLUMN MAPPING -->
              <v-card color="grey lighten-4" v-if="step.type === 'column-mapping'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" class="title">
                      Column mapping
                      <div class="subheading mt-1">
                        Map the column of your .CSV file to the values needed by choosing the column
                        header from the drop down list. If your .CSV file does not contain the needed
                        value select "Not Available"
                      </div>
                    </v-col>
                  </v-row>
                  <v-form v-model="validMap" ref="form">
                    <v-data-table
                      hide-default-footer
                      :items="regFields"
                      :headers="mapHeaders"
                      v-if="parseResuls"
                      :options="{itemsPerPage: -1}"
                      class="elevation-1"
                      style="width: 880px; min-width: 880px; max-width: 880px"
                    >
                      <template v-slot:body="{ items }">
                        <tbody>
                          <tr v-for="(item, i) in items" :key="`${item.name}-${i}`">
                            <td class="text-right" style="width: 180px; min-width: 180px; max-width: 180px; white-space: nowrap; text-overflow: ellipsis;">{{item.name}}</td>
                            <td style="width: 250px; min-width: 250px; max-width: 250px;">
                              <v-select
                                :items="selectOptions"
                                v-model="item.value"
                                label="Column*"
                                hint="Choose a column"
                                dense
                                single-line
                                color="color3"
                                item-color="color3"
                                :rules="[(v) => !!v || 'Choose a column']"
                                style="white-space: nowrap; text-overflow: ellipsis;"
                              >
                                <template slot="item" slot-scope="data">
                                  <v-divider v-if="data.divider"></v-divider>
                                  <v-subheader v-else-if="data.header">{{data.header}}</v-subheader>
                                  <v-list-item-content v-else>{{data.item.text}}</v-list-item-content>
                                </template>
                              </v-select>
                            </td>
                            <td style="width: 150px; min-width: 150px; max-width: 150px; text-align: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                              {{parseResuls.data[0] ? parseResuls.data[0][item.value] : ""}}
                            </td>
                            <td style="width: 150px; min-width: 150px; max-width: 150px; text-align: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                              {{parseResuls.data[1] ? parseResuls.data[1][item.value] : ""}}
                            </td>
                            <td style="width: 150px; min-width: 150px; max-width: 150px; text-align: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                              {{parseResuls.data[2] ? parseResuls.data[2][item.value] : ""}}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-form>
                  <v-row dense>
                    <v-col cols="12" class="d-flex">
                      <v-btn color="color3" class="xsmall" text @click.stop="onNaClick">Mark all remaining as Not Available</v-btn>
                      <v-spacer></v-spacer>
                      <v-switch
                        v-if="playerCount <= 2"
                        label="Add Team Name"
                        color="success"
                        v-model="addTeamname"
                        small hide-details class="mt-0 shrink"
                      ></v-switch>
                      <v-switch
                        label="Add Tags"
                        color="success"
                        v-model="addTags"
                        small hide-details class="mt-0 shrink"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- TRUST FINISHES -->
              <v-card color="grey lighten-4" v-if="step.type === 'trust-finish'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" class="title">
                      You have mapped the teams finishes to the {{mappedFields['Finish']}} column of your spreadsheet.
                    </v-col>
                    <v-col cols="12" class="title">
                      Have you double check the accuracy?
                      <v-radio-group v-model="finishThem">
                        <v-radio label="No, do not submit the results yet" :value="false"></v-radio>
                        <v-radio label="Yes, Submit the results" :value="true"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <!-- CONFIRM -->
              <v-card color="grey lighten-4" v-if="step.type === 'confirm'">
                <v-container>
                  <v-row dense>
                    <v-col cols="12" class="title" v-if="parseResuls">
                      You are about to import the {{toBeImported.length}} registration
                      records in {{currentFile.name}} to {{tourney.name}}
                    </v-col>
                    <v-col cols="12" v-if="tournament && tourney.teamCount > 0" class="title">
                      <v-alert type="error" :value="true">
                        This tournament has existing teams.
                      </v-alert>
                      Would you like to
                      <v-radio-group v-model="overwrite" :mandatory="false">
                        <v-radio label="Add the imported teams" :value="false"></v-radio>
                        <v-radio label="Overwrite the existing teams" :value="true"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        color="color3"
                        label="I have reviewed all the mappings and would like to complete the upload"
                        v-model="mapConfirmed"
                      ></v-checkbox>
                      <v-checkbox
                        v-if="mappedFields['Finish'] && mappedFields['Finish'] !== '~~~'"
                        color="color3"
                        label="The finishes are accurate and i would like to submit the results"
                        v-model="finishThem"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-if="user && user.id === 1">
                    <v-col>
                      <v-btn color="success" @click="testMe">Test Me</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-divider></v-divider>
        <!-- BUTTONS -->
        <v-card-actions>
          <!-- BACK -->
          <v-fab-transition>
            <v-btn
              v-if="previousStep"
              color="color3Text color3--text"
              fab
              @click="onBackClick"
            ><v-icon>fas fa-caret-left</v-icon></v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              v-if="previousStep"
              color="color3"
              text
              @click="onBackClick"
            >Back</v-btn>
          </v-fab-transition>
          <v-spacer></v-spacer>
          <!-- NEXT -->
          <v-fab-transition>
            <v-btn
              color="color3"
              text
              :disabled="!currentStep.valid"
              @click="currentStep.onClick"
            >{{currentStep.nextText || 'Next'}}</v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              color="color3 color3Text--text"
              fab
              :disabled="!currentStep.valid"
              @click="currentStep.onClick"
              :loading="loading"
            ><v-icon>{{currentStep.nextIcon || 'fas fa-caret-right'}}</v-icon></v-btn>
          </v-fab-transition>
        </v-card-actions>
      </div>
    </v-expand-transition>

    <v-dialog v-model="workingDialog" max-width="500px" persistent>
      <v-card v-if="busy">
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>Working!</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-center">
          <h3>Please wait, we are</h3>
          <v-progress-circular indeterminate v-bind:size="50" color="color3"></v-progress-circular>
          <h3>uploading your registrations.</h3>
        </v-card-text>
      </v-card>
      <v-card v-else-if="error">
        <v-toolbar color="error">
          <v-toolbar-title>Error!</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-center">
          We seemed to have had an issue<br>
          <b>{{error}}</b><br>
          If the problem persists please contact support.
        </v-card-text>
        <v-card-actions>
          <v-btn @click.stop="workingDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>Success!</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-center title">
          Your upload is complete.
          <div class="subheading">We are still verifying numbers and calculating points. The teams will start to appear on your teams page over the next few minutes.</div>
          <v-btn
            v-if="t"
            color="color3 color3Text--text"
            :to="{name: 'tournament-brochure', params: {tournamentId: tourney.id}}"
          >Go to tournament</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="complete" text color="color3">Got it</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Papa from 'papaparse'
import uniq from 'lodash.uniq'
import moment from 'moment'
import Registration from '@/classes/TournamentRegistration'
import Summary from '@/classes/TournamentSummary'
import Tournament from '@/classes/Tournament'
import firstBy from 'thenby'
import SanctioningBodies from '@/components/Tournament/Edit/SanctioningBodies.vue'
import PointSystemOptions from '@/components/Tournament/Edit/PointSystemOptions.vue'
import DateTimePicker from '../../Utils/DateTimePicker.vue'
import { mapGetters } from 'vuex'
const NA = '~~~'

export default {
  props: ['tournament', 'active', 'username'],
  data () {
    return {
      selector: false,
      summaries: [],
      tournamentId: null,
      t: null,
      tempT: null,
      finishThem: false,
      overComplete: false,
      window: 0,
      loading: false,
      busy: false,
      currentFile: null,
      parseResuls: null,
      regFields: [
        { name: 'Finish', value: '', map: ['finish ', 'finish', 'place'] },
        { name: 'Player 1 First Name', value: '', map: ['1,fi,name', '1,name'] },
        { name: 'Player 1 Last Name', value: '', map: ['1,last,name', '1,name'] },
        { name: 'Player 1 AAU Number', value: '', map: ['1,aau'] },
        { name: 'Player 1 AVP Number', value: '', map: ['1,volleyam', '1,avp'] },
        { name: 'Player 1 p1440 Number', value: '', map: ['1,p14'] },
        { name: 'Player 1 USAV Number', value: '', map: ['1,usav'] },
        { name: 'Player 1 Phone', value: '', map: ['1,phone'] },
        { name: 'Player 1 Email', value: '', map: ['1,email'] },
        { name: 'Player 1 Birthdate', value: '', map: ['1,birth', '1,dob'] },
        { name: 'Player 1 City', value: '', map: ['1,city'] },
        { name: 'Player 1 State', value: '', map: ['1,state', '1,st'], not: ['first', 'last'] },
        { name: 'Player 1 Zip', value: '', map: ['1,zip'] },
        { name: 'Player 1 Club', value: '', map: ['1,club'] },
        { name: 'Player 2 First Name', value: '', map: ['2,fi,name', '2,name'] },
        { name: 'Player 2 Last Name', value: '', map: ['2,last,name', '2,name'] },
        { name: 'Player 2 AAU Number', value: '', map: ['2,aau'] },
        { name: 'Player 2 AVP Number', value: '', map: ['2,volleyam', '2,avp'] },
        { name: 'Player 2 p1440 Number', value: '', map: ['2,p14'] },
        { name: 'Player 2 USAV Number', value: '', map: ['2,usav'] },
        { name: 'Player 2 Phone', value: '', map: ['2,phone'] },
        { name: 'Player 2 Email', value: '', map: ['2,email'] },
        { name: 'Player 2 Birthdate', value: '', map: ['2,birth', '2,dob'] },
        { name: 'Player 2 City', value: '', map: ['2,city'] },
        { name: 'Player 2 State', value: '', map: ['2,state', '2,st'], not: ['first', 'last'] },
        { name: 'Player 2 Zip', value: '', map: ['2,zip'] },
        { name: 'Player 2 Club', value: '', map: ['2,club'] }
      ],
      hasHeader: true,
      divisionColumn: null,
      divisionList: null,
      divisionMap: null,
      validMap: false,
      validDivMap: false,
      mapHeaders: [
        { text: 'Value Needed', sortable: false, align: 'right' },
        { text: 'Choose A Column', sortable: false, align: 'left' },
        { text: 'Sample Data 1', sortable: false, align: 'left' },
        { text: 'Sample Data 2', sortable: false, align: 'left' },
        { text: 'Sample Data 3', sortable: false, align: 'left' }
      ],
      overwrite: false,
      mapConfirmed: false,
      workingDialog: false,
      error: null,
      autoAdj: false,
      states: [],
      addTeamname: false,
      addTags: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    tourney () {
      return this.tournament || this.t
    },
    playerCount () {
      return this.tourney ? Math.max(...this.tourney.publicDivisions.map(m => m.numAllowedOnRoster)) : 2
    },
    steps () {
      let s = 1
      const steps = [
        {
          value: s++,
          type: 'tournament-select',
          key: 'tournament-select',
          title: 'Tournament',
          valid: this.tournamentId,
          skip: !!this.tournament,
          onClick: this.onNextClick
        },
        {
          value: s++,
          type: 'tournament-complete',
          key: 'tournament-complete',
          title: 'Warning!',
          valid: this.overComplete,
          skip: !this.tourney || (this.tourney && !this.tourney.isComplete),
          onClick: this.onNextClick
        },
        {
          value: s++,
          type: 'tournament-create',
          key: 'tournament-create',
          title: 'Tournament Quick Add',
          valid: true,
          skip: this.tourney,
          nextText: 'Got It',
          onClick: this.onNextClick
        },
        {
          value: s++,
          type: 'tournament-create2',
          key: 'tournament-create2',
          title: 'Tournament Quick Add',
          valid: this.tempT && this.tempT.name && this.tempT.startDate,
          skip: this.tourney,
          onClick: () => {
            this.saveTournament()
            this.onNextClick()
          }
        },
        {
          value: s++,
          type: 'select-file',
          key: 'select-file',
          title: 'Upload File',
          valid: !!this.currentFile,
          skip: false,
          onClick: this.onNextClick
        },
        {
          value: s++,
          type: 'verify-data',
          key: 'verify-data',
          title: 'Verify Data',
          valid: true,
          skip: false,
          nextText: 'Yep, it looks good',
          onClick: this.onNextClick
        },
        {
          value: s++,
          type: 'division-mapping',
          key: 'division-mapping',
          title: 'Map The Divisions',
          valid: this.validDivMap,
          skip: false,
          onClick: this.onNextClick
        },
        {
          value: s++,
          type: 'column-mapping',
          key: 'column-mapping',
          title: 'Map The Columns',
          valid: this.validMap,
          skip: false,
          onClick: this.onNextClick
        },
        {
          value: s++,
          type: 'trust-finish',
          key: 'trust-finish',
          title: 'Submit Results?',
          valid: this.validMap,
          skip: !this.mappedFields.Finish || this.mappedFields.Finish === NA,
          onClick: this.onNextClick
        },
        {
          value: s++,
          type: 'confirm',
          key: 'confirm',
          title: 'Confirm & Upload',
          valid: this.mapConfirmed,
          skip: false,
          complete: true,
          nextText: 'Just import them already!',
          nextIcon: 'fas fa-upload',
          onClick: this.onCompleteClick
        }
      ]

      return steps
    },
    currentI () {
      return this.steps.findIndex(f => f.value === this.window)
    },
    currentStep () {
      return this.steps.find(f => f.value === this.window)
    },
    step1 () {
      return this.steps.find(f => f.value > 0 && !f.skip)
    },
    nextStep () {
      return this.steps.find(f => f.value > this.window && !f.skip)
    },
    previousStep () {
      var x = this.steps.filter(f => f.value < this.window && !f.skip)
      return x && x[x.length - 1]
    },
    nextBtn () {
      return this.currentStep ? {
        show: this.nextStep || this.currentStep.complete,
        disabled: !this.currentStep.valid,
        onClick: this.currentStep.complete ? this.onCompleteClick : this.onNextClick,
        text: this.currentStep.nextText || 'Next',
        icon: this.currentStep.nextIcon || 'fas fa-caret-right',
        loading: this.loading
      } : null
    },
    selectOptions () {
      return [
        { text: 'Not Available', value: NA },
        { divider: true },
        { header: 'Columns' },
        ...this.columnOptions
      ]
    },
    columnOptions () {
      const options = []
      if (this.parseResuls) {
        for (const prop in this.parseResuls.data[0]) {
          options.push({ text: this.hasHeader ? `${prop}` : `Col ${prop}`, value: prop })
        }
      }
      return options
    },
    rows () {
      return this.parseResuls && this.parseResuls.data
    },
    headers () {
      const head = []
      if (this.parseResuls) {
        for (const prop in this.parseResuls.data[0]) {
          head.push({
            text: this.hasHeader ? `${prop}` : `Col ${prop}`,
            value: `${prop}`,
            sortable: false,
            align: 'left'
          })
        }
      }
      return head
    },
    unMappedFields () {
      return this.regFields.filter(f => !f.value)
    },
    mappedFields () {
      var result = {}
      for (const f of this.regFields) {
        result[f.name] = f.value
      }
      return result
    },
    divisionOptions () {
      if (!this.tourney) return []
      var options = [...this.tourney.publicDivisions]
      options.push({ name: 'Do not import', id: 0 })
      return options
    },
    mappedDivisions () {
      return this.divisionList && this.divisionList.filter(f => this.divisionMap && this.divisionMap[f] && this.divisionMap[f].id)
    },
    toBeImported () {
      return this.parseResuls && this.mappedDivisions ? this.parseResuls.data.filter(f => {
        return this.mappedDivisions.includes(f[this.divisionColumn])
      }) : []
    },
    tournamentList () {
      if (this.summaries) {
        const result = this.summaries.map(s => {
          return {
            id: s.id,
            name: `${s.name} ${s.startDate.format('ll')}`,
            timestamp: s.timestamp
          }
        })
        result.sort(firstBy('timestamp', { direction: -1 }))

        return result
      }
      return []
    },
    selectedSummary () {
      return this.tournamentId && this.summaries.find(f => f.id === this.tournamentId)
    },
    organizations () {
      return this.user.pages.map((p) => {
        return {
          id: p.id,
          name: p.name,
          username: p.username
        }
      })
    },
    showAdjust () {
      if (!this.divisionMap) return false
      let r = false
      for (const prop in this.divisionMap) {
        if (prop.toLowerCase().includes('gold')) {
          const id = this.divisionMap[prop] && this.divisionMap[prop].id
          if (id) {
            for (const p2 in this.divisionMap) {
              const id2 = this.divisionMap[p2] && this.divisionMap[p2].id
              if (p2.toLowerCase().includes('silver') && id === id2) {
                r = true
                break
              }
            }
          }
        }
        if (r) break
      }
      return r
    }
  },
  methods: {
    onBackClick () {
      if (!this.previousStep) return
      this.window = this.previousStep.value
    },
    onNextClick () {
      this.$refs && this.$refs.form && this.$refs.form[0].validate()
      if (!this.nextStep) return
      const n = this.nextStep.value
      this.window = n
    },
    onCompleteClick () {
      console.log(this.mapRegistrations())
      this.upload()
    },
    onPickFile () {
      this.$refs.fileInput[0].click()
    },
    onFilePicked (event) {
      const file = event.target.files[0]
      if (!file) return
      if (file.name.lastIndexOf('.csv') <= 0) {
        alert('Please select a CSV file')
        return
      }
      this.busy = true
      this.currentFile = file
      this.parseFile()
      this.onNextClick()
      this.busy = false
    },
    parseFile () {
      Papa.parse(this.currentFile, {
        header: this.hasHeader,
        complete: (results, file) => {
          this.currentFile = file

          for (const e of results.errors) {
            results.data.splice(e.row, 1)
          }
          const checkProperties = (obj) => {
            for (var key in obj) {
              if (obj[key] !== null && obj[key] !== '') return true
            }
            return false
          }

          results.data = results.data.filter((r) => checkProperties(r))

          this.parseResuls = results
          this.tryMap()
          this.initDivisionList()
        }
      })
    },
    tryMap () {
      const dc = this.selectOptions.filter(f => f.value).filter(f => f.value.toLowerCase().includes('division'))
      if (dc.length > 0) this.divisionColumn = dc[0].value
      const fields = this.regFields.filter((f) => { return f.map && f.map.length > 0 })

      for (const field of fields) { // each field that has a map array
        for (const m of field.map) {
          let options = this.selectOptions.filter(f => f.value) // column headers
          const filters = m.split(',')
          for (const filter of filters) {
            options = options.filter(o => o.value.toLowerCase().includes(filter.toLowerCase()))
          }
          if (field.not) {
            for (const x of field.not) {
              options = options.filter(o => !o.value.toLowerCase().includes(x.toLowerCase()))
            }
          }

          if (options.length > 0) {
            field.value = options[0].value
            break
          }
        }
      }
    },
    clear () {
      this.currentFile = null
      this.parseResuls = null
      this.$refs.fileInput[0].value = null
      this.window = 0
      this.t = this.tempT = this.tournamentId = null
      this.selector = this.finishThem = this.overComplete = false
      this.window = this.nextStep.value
    },
    initDivisionList () {
      if (!this.divisionColumn) return
      this.divisionList = uniq(this.parseResuls.data.map(r => r[this.divisionColumn]))
      const map = {}
      for (const d of this.divisionList) {
        map[d] = null
      }

      this.divisionMap = map
      if (this.divisionColumn) {
        var opt = this.columnOptions.find(f => f.value === this.divisionColumn)
        if (!opt) {
          this.divisionColumn = null
        }
      }
    },
    onNaClick () {
      this.unMappedFields.forEach(f => {
        f.value = NA
      })
    },
    mapRegistrations () {
      const registrations = []

      if (!this.mappedFields || !this.parseResuls || !this.parseResuls.data || !this.tourney || !this.divisionMap || !this.divisionColumn) {
        return registrations
      }

      const map = this.mappedFields
      for (const record of this.toBeImported) {
        const registration = new Registration()
        registration.tournamentId = this.tourney.id
        registration.setDivision(this.divisionMap[record[this.divisionColumn]])
        registration.dBracket = record[this.divisionColumn]

        const players = this.playerCount
        if (players > 2 || this.addTeamname) {
          registration.teamName = record[map['Team Name']]
        }
        if (this.addTags) {
          registration.tags = record[map['Team Tags']]
        }

        for (let i = 1; i <= players; i++) {
          if (!(map[`Player ${i} First Name`] === '~~~' && map[`Player ${i} Last Name`] === '~~~')) {
            if (registration.players.length < i) registration.addPlayer()
            const p = registration.players[i - 1]

            if (map[`Player ${i} First Name`] === map[`Player ${i} Last Name`]) {
              var name = record[map[`Player ${i} First Name`]].split(' ')
              p.lastName = name.pop()
              p.firstName = name.join(' ')
            } else {
              p.firstName = record[map[`Player ${i} First Name`]]
              p.lastName = record[map[`Player ${i} Last Name`]]
            }
            p.phone = record[map[`Player ${i} Phone`]]
            p.phone && p.phone.replace(/\D/g, '')
            p.email = record[map[`Player ${i} Email`]]
            if (record[map[`Player ${i} Birthdate`]]) {
              p.dob = moment(record[map[`Player ${i} Birthdate`]]).format('YYYY-MM-DD')
              if (p.dob === 'Invalid date') p.dob = null
              const dob1 = moment(record[map[`Player ${i} Birthdate`]])
              if (dob1.isValid()) {
                p.dob = dob1.isAfter('1900-01-01', 'year') ? dob1.format('YYYY-MM-DD') : null
              } else {
                p.dob = null
              }
            }
            p.city = record[map[`Player ${i} City`]]
            p.state = record[map[`Player ${i} State`]]
            p.aauNumber = record[map[`Player ${i} AAU Number`]]
            p.avpNumber = record[map[`Player ${i} AVP Number`]]
            p.p1440Number = record[map[`Player ${i} p1440 Number`]]
            p.usavNumber = record[map[`Player ${i} USAV Number`]]
            p.club = record[map[`Player ${i} Club`]]
            p.clean(this.states)
          }
        }

        const finish = +record[map.Finish]
        if (finish && finish > 0) {
          registration.finish = finish
        } else {
          registration.finish = 0
        }

        registrations.push(registration.uploadDto)
      }
      if (this.autoAdj) this.adjustFinishes(registrations)
      return registrations
    },
    adjustFinishes (r) {
      var divs = new Set(r.map(m => m.tournamentDivisionId))
      divs.forEach(d => {
        var teams = r.filter(f => f.tournamentDivisionId === d)
        var brackets = [...new Set(teams.map(m => m.dBracket))]
        if (brackets.length > 1) {
          var g = brackets.find(f => f.toLowerCase().includes('gold'))
          var s = brackets.find(f => f.toLowerCase().includes('silver'))
          var c = brackets.find(f => f.toLowerCase().includes('copper'))
          var b = brackets.find(f => f.toLowerCase().includes('bronze'))
          if (g && s) {
            var gold = teams.filter(f => f.dBracket === g)
            var adj = gold.length
            if (adj) {
              var silver = teams.filter(f => f.dBracket === s)
              silver.forEach(s => {
                s.finish += adj
              })
              adj += silver.length
              if (b) {
                var bronze = teams.filter(f => f.dBracket === b)
                bronze.forEach(s => {
                  s.finish += adj
                })
                adj += bronze.length
              }
              if (c) {
                var copper = teams.filter(f => f.dBracket === c)
                copper.forEach(s => {
                  s.finish += adj
                })
              }
            }
          }
        }
      })
    },
    testMe () {
      console.log(this.mapRegistrations())
    },
    upload () {
      this.busy = true
      this.workingDialog = true
      this.error = null

      const registrations = this.mapRegistrations()
      this.$VBL.tournament.putBulkRegister(this.overwrite, registrations, this.finishThem)
        .catch((e) => {
          this.error = e.response.data
          console.log(e)
        })
        .finally(() => { this.busy = false })
    },
    complete () {
      this.$emit('complete')
      this.workingDialog = false
    },
    getTournamentList () {
      this.loading = true
      const query = `active=false&organizationUserName=${this.username}&withCounts=true&coOp=false`
      this.$VBL.tournament.getAllSummaries(query)
        .then((response) => {
          this.summaries = response.data.map(s => new Summary(s))
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getTournament () {
      if (!this.tournamentId) return
      this.loading = true
      this.$VBL.tournament.getById(this.tournamentId)
        .then(r => {
          this.t = new Tournament(this.$VBL, r.data)
          this.onNextClick()
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveTournament () {
      this.loading = true
      this.$VBL.tournament.saveQuick(this.tempT)
        .then(r => {
          this.t = new Tournament(this.$VBL, r.data)
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    createTemp () {
      this.t = null
      this.tournamentId = null
      this.selector = false
      this.tempT = {
        id: 0,
        name: null,
        startDate: null,
        sanctioningBody: null,
        pointSystems: [],
        divisions: [
          { days: [] }
        ]
      }
      this.tempT.organization = this.organizations.find(f => f.username === this.username)
      this.onNextClick()
    },
    setFields () {
      const r = [
        { name: 'Finish', value: '', map: ['finish ', 'finish', 'place'] }
      ]
      const players = this.playerCount
      if (players > 2 || this.addTeamname) {
        r.push({ name: 'Team Name', value: '', map: ['team'] })
      }
      if (this.addTags) {
        r.push({ name: 'Team Tags', value: '', map: ['tags'] })
      }

      for (let i = 1; i <= players; i++) {
        r.push(
          { name: `Player ${i} First Name`, value: '', map: [`${i},fi,name`, `${i},name`] },
          { name: `Player ${i} Last Name`, value: '', map: [`${i},last,name`, `${i},name`] },
          { name: `Player ${i} AAU Number`, value: '', map: [`${i},aau`] },
          { name: `Player ${i} AVP Number`, value: '', map: [`${i},volleyam`, `${i},avp`] },
          { name: `Player ${i} p1440 Number`, value: '', map: [`${i},p14`] },
          { name: `Player ${i} USAV Number`, value: '', map: [`${i},usav`] },
          { name: `Player ${i} Phone`, value: '', map: [`${i},phone`] },
          { name: `Player ${i} Email`, value: '', map: [`${i},email`] },
          { name: `Player ${i} Birthdate`, value: '', map: [`${i},birth`, `${i},dob`] },
          { name: `Player ${i} City`, value: '', map: [`${i},city`] },
          { name: `Player ${i} State`, value: '', map: [`${i},state`, `${i},st`], not: ['first', 'last'] },
          { name: `Player ${i} Zip`, value: '', map: [`${i},zip`] },
          { name: `Player ${i} Club`, value: '', map: [`${i},club`] }
        )
      }
      this.regFields = r
    },
    getStates () {
      if (this.states.length) return
      this.loading = true
      this.$http.get(`${window.location.origin}/static/json/states.json`)
        .then(r => {
          this.states = r.data
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    }
  },
  mounted () {
    this.window = this.step1.value
    this.setFields()
    this.getStates()
  },
  watch: {
    active: function (n, o) {
      this.setFields()
      if (o && !n) this.clear()
      this.window = this.step1.value
    },
    hasHeader () {
      this.parseFile()
    },
    divisionColumn: 'initDivisionList',
    selector: function (v) {
      if (v && !this.summaries.length) {
        this.getTournamentList()
      }
    },
    tournamentId: 'getTournament',
    workingDialog: function (val) {
      if (!val) {
        this.clear()
      }
    },
    playerCount: 'setFields',
    addTeamname: 'setFields',
    addTags: 'setFields'
  },
  components: {
    SanctioningBodies,
    DateTimePicker,
    PointSystemOptions
  }
}
</script>

<style>

</style>
